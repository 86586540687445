.ant-layout-content {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  grid-template-areas:
    "main-content"
    "sidebar";
}

.main-content {
  padding: 0.5em;
  grid-area: main-content;
}
.sidebar {
  padding: 0.5em;
  grid-area: sidebar;
}

.detail-card {
  border-radius: 4px;
  padding: 0.5em 1em;
  background: #d9dee4;
  margin-top: 1em;
  margin-bottom: 1em;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.4);
}
.App .ant-carousel .mobile-banner {
  display: block;
}
.App .desktop-banner {
  display: none;
}
.main-products {
  display: flex;
  margin-bottom: 1em;
}
.main-products img {
  width: 25%;
  border-radius: 0.25em;
  padding: 0.125em;
  width: 100%;
}
.App .desktop-banner img {
  width: 25%;
  margin: 0;
}
.brand-image img {
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.4);
}

.hide-for-sm {
  display: none;
}

.line-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background: #00b900;
  color: #ffffff;
  font-size: 1rem;
}
.line-button:hover {
  background: #008400;
  color: #ffffff;
}

@media screen and (min-width: 768px) {
  .App .desktop-banner {
    display: flex!important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .App .ant-carousel .mobile-banner {
    display: none;
  }
  .main-products {
    display: flex;
    margin-bottom: 1em;
  }
  .main-products img {
    width: 25%;
    border-radius: 0.5em;
  }
  
  .hide-for-sm {
    display: initial;
  }
}
@media screen and (min-width: 992px) {
  .ant-layout-content {
    grid-template-columns: 66.66667% 33.33333%;
    grid-template-rows: auto;
    grid-template-areas:
      "main-content sidebar";
  }
  .ant-layout-content {
    margin-right: -0.5em;
    margin-left: -0.5em;
  }
  .detail-card {
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
