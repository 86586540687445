@import url('https://fonts.googleapis.com/css?family=Prompt|Sarabun:400,500&display=swap');

body {
  background: #f0f2f5;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Sarabun';
  font-weight: 500;
}

h1 {
  font-size: 2em;
}
img {
  max-width: 100%;
  height: auto;
}
.page-title {
  margin-top: 0.5em;
  animation: slideIn 1s ease-out;
}
.ant-carousel .slick-slide {
  text-align: center;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.rate-table {
  margin: 1em auto;
}
.rate-table thead {
  background: rgb(238, 81, 173);
}
.rate-table tbody {
  background: rgb(234, 240, 149);
}
.rate-table th {
  font-family: 'Sarabun';
  font-weight: 400;
  color: white;
}
.rate-table th,
.rate-table td {
  text-align: center;
  padding: 0.5em 1em;
  border: 4px solid #f0f2f5;
  font-size: 16px;
  line-height: 24px;
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}
.text-lg {
  font-size: 16px;
  line-height: 24px;
}
.text-xl {
  font-size: 18px;
  line-height: 26px;
}
.text-bold {
  font-weight: bold;
}
.text-primary {
  color: #0644a2;
}
.list-no-bullet {
  list-style: none;
  padding-left: 0.5em;
}

.ant-back-top {
  bottom: 36px;
}

.ant-layout-footer {
  background: #333;
  color: #fff;
}
.container {
  width: 100%;
}

.gallery {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "second first first forth"
    "third first first fifth"
    "sixth seventh eighth ninth";
  margin: 1em 0;
}
.gallery img {
  border-radius: 0.125em;
  width: 100%;
}
.gallery img:nth-child(1) {
  grid-area: first;
}
.gallery img:nth-child(2) {
  grid-area: second;
}
.gallery img:nth-child(3) {
  grid-area: third;
}
.gallery img:nth-child(4) {
  grid-area: forth;
}
.gallery img:nth-child(5) {
  grid-area: fifth;
}
.gallery img:nth-child(6) {
  grid-area: sixth;
}
.gallery img:nth-child(7) {
  grid-area: seventh;
}
.gallery img:nth-child(8) {
  grid-area: eighth;
}
.gallery img:nth-child(9) {
  grid-area: ninth;
}

.rich-lace {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2em;
  padding: 2em 1em 1em;
}
.rich-lace img {
  max-width: 90%;
  margin-bottom: 2em;
}

@media screen and (min-width: 576px) {
  .container {
    max-width: 540px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 768px) {
  body {
    font-size: 16px;
    line-height: 1.5;
  }
  .container {
    max-width: 720px;
  }
  .page-title {
    font-size: 2.75em;
    margin-top: 0.5em;
  }
  
  .rich-lace {
    flex-direction: row;
    margin-bottom: 0;
    padding: 0;
  }
  .rich-lace img {
    max-width: 35%;
    margin: 1em 2em 1em 0;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(250px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
